import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Ul from 'components/new/ul'
import LocationsMapSearch from 'components/locations-map/locations-map-search'

const TurfMaintenanceAgreementsPage = props => {
  const {
    data: {
      allSanityLocation: { nodes: allSanityLocationNodes },
      heroImage,
    },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>Turf Equipment Service Plans | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Turf Equipment Service Plans'
        overlayOpacity={0.3}
      />
      <Content kind='full'>
        <H2>You get a deal, we'll deal with the service</H2>
        <P>
          We've made some changes to our winter turf equipment service. This year you will find two
          different levels of service on some equipment and the option to save money by pre-buying
          upcoming services.
        </P>
        <Ul>
          <li>
            Two service options (Select & Ultimate) on certain types of equipment to better fit your
            needs and budget
          </li>
          <li>Free pick up and delivery with Ultimate service plans (up to 20 miles)</li>
          <li>50% off pick up and delivery with Select service plans (up to 20 miles)</li>
          <li>Purchase upcoming services and get a discount, plus lock in today's service rates</li>
        </Ul>
      </Content>
      <LightSection>
        <Content kind='full'>
          <H2 id='parts-warranty'>Service Plans</H2>
          <Spacer size='m' />
          <Grid>
            <Column>
              <H3>Small Lawn Mowers</H3>
              <P>
                John Deere D100, S200, X300, Z300 Series and like model lawn mowers and zero-turns
              </P>
              <Spacer size='s' />
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/service/turf-maintenance-agreements/small-lawn-mowers/'
                  ghost
                  color='green'
                >
                  View service plans
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <H3>Mid-Size & Large Lawn Mowers</H3>
              <P>
                John Deere X500, Z500, X700, Z700, Z900 Series and like model lawn mowers and
                zero-turns
              </P>
              <Spacer size='s' />
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/service/turf-maintenance-agreements/mid-size-large-lawn-mowers/'
                  ghost
                  color='green'
                >
                  View service plans
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <H3>Utility Vehicles</H3>
              <P>John Deere Gator utility vehicles</P>
              <Spacer size='s' />
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/service/turf-maintenance-agreements/utility-vehicles/'
                  ghost
                  color='green'
                >
                  View service plans
                </Button>
              </ButtonContainer>
            </Column>
            <Column>
              <H3>Compact Utility Tractors</H3>
              <P>John Deere 1-4 Series and like model copmact utility tractors</P>
              <Spacer size='s' />
              <ButtonContainer>
                <Button
                  as={Link}
                  to='/service/turf-maintenance-agreements/compact-utility-tractors/'
                  ghost
                  color='green'
                >
                  View service plans
                </Button>
              </ButtonContainer>
            </Column>
          </Grid>
        </Content>
      </LightSection>
      <Content kind='full'>
        <H2>Contact your nearest service shop to learn more</H2>
      </Content>
      <LocationsMapSearch locations={allSanityLocationNodes} />
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
  }
`

const Column = styled.div`
  background-color: #fff;
  padding: 24px;

  :not(:last-child) {
    margin-bottom: ${props => props.theme.size.m};
  }

  @media (min-width: 900px) {
    ${column('1/3', 24)}
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query turfMaintenanceAgreementsPageQuery {
    heroImage: file(
      relativePath: {
        eq: "service/maintenance-agreements/lawn-tractor-service-dsc02057-2-hero.jpg"
      }
    ) {
      ...FullWidthImage
    }
    allSanityLocation(
      filter: { retailLocation: { eq: true } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        address
        city
        directionsLink
        geolocation {
          lat
          lng
        }
        hours {
          sunday
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
        }
        locationNumber
        phoneNumber
        slug
        specialHours {
          date
          hours
          title
        }
        state
        status
        title
        zip
      }
    }
  }
`

export default TurfMaintenanceAgreementsPage
