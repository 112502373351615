import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const Ul = ({ children, ...props }) => {
  return <StyledUl {...props}>{children}</StyledUl>
}

Ul.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledUl = styled.ul`
  color: ${props => props.theme.color.n900};
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin: 8px 0;

  > li {
    margin: 0 0 4px;

    :last-child {
      margin: 0;
    }
  }
`

export default Ul
